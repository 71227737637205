import React from 'react';
import './App.css';
import Head from "./components/head";

class App extends React.Component {




    render() {
        return (
            <div className="App">
                <div className="screen">
                    <div className="screen-title">
                        Chris Endcliffe
                    </div>
                    <div className="screen-subtitle">
                       awesome. digital guru & web developer
                    </div>

                    <div className="screen-links">
                        <a className={'screen-link'}  target={'_blank'} href="https://wemakethingsawesome.com"><i className="fas fa-link"></i></a>
                        <a className={'screen-link'} target={'_blank'} href="https://www.linkedin.com/in/chris-endcliffe/"><i className="fab fa-linkedin"></i></a>
                        <a className={'screen-link'} target={'_blank'} href="https://twitter.com/chrisendcliffe"><i className="fab fa-twitter-square"></i></a>
                        <a className={'screen-link'} target={'_blank'} href="https://www.artstation.com/brainy"><i className="fab fa-artstation"></i></a>
                        <a className={'screen-link'} target={'_blank'} href="https://www.instagram.com/awesomenessdev/"><i className="fab fa-instagram"></i></a>
                        <a className={'screen-link'} target={'_blank'} href="https://www.youtube.com/user/ChrisBrainAudio/featured"><i className="fab fa-youtube"></i></a>
                    </div>
                    <Head />
                    <div className="screen-tag">
                        Need web or digital support? Take me for a spin.
                    </div>

                </div>
            </div>
        );
    }


}

export default App;
