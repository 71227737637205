import React from 'react';
import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import  {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';


export default class Head extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded:  false
        };
    }


    componentDidMount() {
        this.el = document.getElementById('head-component');
        this.renderer = new THREE.WebGLRenderer({ antialias : true, alpha: true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize( this.getFrameWidth(), this.getFrameHeight());
        this.camera = new THREE.PerspectiveCamera(55, this.getFrameWidth() / this.getFrameHeight(), 1, 1000);
        this.camera.target = new THREE.Vector3(0,0,0);
        this.addEventListeners();
        this.scene = new THREE.Scene();
        this.el.appendChild(this.renderer.domElement);
        this.renderer.render(this.scene, this.camera);
        this.animate();
        this.loadHead();
        this.lights();
        this.addControls();
        this.renderer.setClearColor( 0xffffff, 0);

    }

    getFrameHeight(){
        return this.el.clientHeight;
    }

    getFrameWidth() {
            return this.el.clientWidth;
    }

    addControls() {
        this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        this.controls.autoRotate = true;
        this.controls.enableZoom = false;
        this.controls.enableDamping = true;
        this.controls.minPolarAngle =  Math.PI/2;
        this.controls.maxPolarAngle = Math.PI/2;
        this.camera.position.set( 0, 20, 135 );
        this.controls.update();
    }

    lights() {
        let ambient = new THREE.AmbientLight(0x404040);
        ambient.intensity = 8;
        this.scene.add(ambient);

    }

    loadHead() {
        let loader = new GLTFLoader();
        loader.load('/model/head.gltf', model => {
            this.setState({loaded:  true});
            this.scene.add(model.scene);
            model.scene.position.set(0, -80, 0);
            model.scene.scale.set(1.5,1.5,1.5);

        });


    }

    addEventListeners() {
        window.addEventListener( 'resize', () => {
            this.camera.aspect = this.getFrameWidth()/ this.getFrameHeight();
            this.camera.updateProjectionMatrix();
            this.renderer.setSize( this.getFrameWidth(), this.getFrameHeight());
        }, false );
    }

    animate() {
        requestAnimationFrame(() => this.animate())
        this.renderer.render(this.scene, this.camera);
        if(this.controls) {
            this.controls.update();

        }
    }


    render() {
        return (
            <div id={'head-component'}>
                {
                    !this.state.loaded &&
                        <>Loading me!</>
                }
            </div>
        );
    }

}